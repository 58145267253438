export type ButtonConfigVariantType =
  | 'primary'
  | 'primaryOnContrast'
  | 'primaryDestructive'
  | 'primaryDestructiveOnContrast'
  | 'secondary'
  | 'secondaryOnContrast'
  | 'secondaryDestructive'
  | 'secondaryDestructiveOnContrast'
  | 'secondarySubtle'
  | 'secondarySubtleOnContrast'
  | 'secondarySubtleDestructive'
  | 'secondarySubtleDestructiveOnContrast'
  | 'tertiary'
  | 'tertiaryOnContrast'
  | 'tertiaryDestructive'
  | 'tertiaryDestructiveOnContrast'
  | 'ghost'
  | 'ghostOnContrast'
  | 'ghostNeutral'
  | 'ghostNeutralOnContrast'
  | 'ghostDestructive'
  | 'ghostDestructiveOnContrast'
  | 'ai';

// We are omitting onContrast variants from the variants list because we are setting
// the 'isOnContrast' boolean to determine this state
export const buttonVariants = [
  'primary',
  'primaryDestructive',
  'secondary',
  'secondaryDestructive',
  'secondarySubtle',
  'secondarySubtleDestructive',
  'tertiary',
  'tertiaryDestructive',
  'ghost',
  'ghostNeutral',
  'ghostDestructive',
  'ai',
] as const;

export type ButtonVariant = (typeof buttonVariants)[number];

export const buttonSizes = ['xs', 'sm', 'md', 'lg'] as const;
export type ButtonSize = (typeof buttonSizes)[number];

export type ButtonIconPosition = 'left' | 'right' | 'top';
